export class Response {
	status_code: number;
	error_type: string = null; //HttpErrorResponse

	_headers: any;
	_json: any;
	body: any;

	get headers() {
		if( !this._headers.headers ){
			this._headers.lazyInit();
		}
		return this._headers.headers;
	}

	get json(){
		if( !this._json )
			try { this._json = JSON.parse(this.body); }
			catch(e){ return null; }

		return this._json;
	}
	get ok(){ return this.status_code == 200; }


	constructor( resp ){
		this.status_code = resp.status;
		this.error_type = resp.name;
		this._headers = resp.headers;

		let content = resp.error || resp.body;
		if( typeof( content ) == "string" )
			this.body = content;
		else
			this._json = content;
	}
}



export class User {
	constructor( kwargs ){
		for( let k of Object.keys( kwargs ) )
			this[k] = kwargs[k];
	}

	id: number;
	username: string;
	first_name: string;
	last_name: string;
	email: string;
	telegram_id: number;
	picture: string;
	active_signatures: number;

	groups:  any;
	inviter: any;
	invite_count: number;

	public has_permission( perm: string ): boolean {
		for( let g of Object.values( this.groups ) )
			for( let p of Object.values( g ) )
				if( perm == p.codename ) return true;

		return false;
	}


}


/*


abstract class BaseModel {
	deserialize( kwargs, track_changes = true ){
		kwargs = JSON.parse(JSON.stringify(kwargs));
		for( let k of Object.keys( kwargs ) ){
			let parts = k.split(":")
			if( parts.length == 1 || kwargs[k] === null )
				this[k] = kwargs[k];
			else if( parts[1] ){
				//this[parts[1]] = kwargs[k].map( m => new registered_models[parts[0]]['__proto__']( m, track_changes ) );
			} else this['_meta'] = kwargs[k]
		}
		//if( track_changes )
		//	this._raw_obj = new this['__proto__'].constructor( JSON.parse(JSON.stringify(kwargs)), false );
	}
	_meta: string;
	_raw_obj: any;
	hasChanged(element?: string): boolean {
		if( element === undefined )
			return Object.keys(this.allChanges()).filter(f=>{return !f.startsWith('_')}).length > 0;

		if( this[element] instanceof Array ){
			if( this[element].length != this._raw_obj[element].length ) return true;
			if( this[element].length == 0 ) return false;

			return this[element].map(e=>{ return e['id'] || e }).join() != this._raw_obj[element].map(e=>{ return e['id'] || e }).join()
		}

		return  this[element] != this._raw_obj[element];
	}
	revertChange(element: string) {
		if( this[element] instanceof Array ){
			let order = this._raw_obj[element].map(e => { return e.id });

			if( order.filter(r=>{return r?true:false}).length > 0 )
				this[element].sort( (a, b) => { return order.indexOf(a.id) - order.indexOf(b.id) } );
			else
				this[element] = this._raw_obj[element].slice();

		} else this[element] = this._raw_obj[element];
	}
	allChanges(){
		let changes = { _model: this['__proto__'].constructor.name };


		for( let k of Object.keys(this) ){
			if( k.startsWith('_') ) continue;
			let v = this[k];
			if( k == 'id' ){ changes['_id'] = v; continue }

			if( v instanceof Array ){
				changes[k] = new Array();
				for( let i of v )
					if( i instanceof BaseModel ) changes[k].push( i.allChanges() )

				if( changes[k].length == 0 || ( !this.hasChanged(k) &&
						changes[k].filter( e => {
							return Object.keys(e).filter(f=>{return !f.startsWith('_')}).length > 0
						}).length == 0
					)) delete changes[k];

				if( !changes.hasOwnProperty(k) && this.hasChanged(k) )
					changes[k] = v.slice().filter(r=>{return r? !/^\s*$/.test(r) : false });

			} else if( this.hasChanged(k) )
				changes[k] = v;


		}
		return changes;
	}

}


export class Episode extends BaseModel {
	id: number;
	name: string;
	summary: string;
	air_date: string;
	special: string;

	signatures: any;
	season_id: number;
}

export class Poster extends BaseModel {
	id: number;
	show_id: string;
	original_id: string;
	width: number;
	height: number;
	url: string;
}

export class Show extends BaseModel {
	parent: Show;
	children: Show[];
	get seasons() { return this.children.filter(e=>e.type=="Season") }
	episodes: Episode[];
	posters: Poster[];

	id: string;
	full_name: string; get name(){ return this.full_name; }
	context_name: string;
	other_names: string[];

	style: string;
	status: string;
	type: string;

	poster: string;
	first_aired: string;
	description: string;
	tvdb_id: number;


	get statusClasses(){
		return {
			'blue' : this.status == 'Airing', 'green' : this.status == 'Planned', 'grey' : this.status == 'Ended',
			'orange' : this.status == 'Suspended', 'red' : this.status == 'Canceled',
			'brown' : this.status == 'Unknown'
		}
	}

	get styleClasses(){
		return {
			'blue'   : this.style == 'Anime', 'green' : this.style == 'Cartoon',
			'orange' : this.style == 'Film',  'brown' : this.style == 'Other'
		}
	}

	get typeClasses(){
		return {
			'blue' : this.type  == 'Anime', 'green' : this.type   == 'Cartoon',
			'grey' : this.type  == 'Live Action', 'orange' : this.type   == 'Movie',
			'brown' : this.type  == 'Other'
		}
	}

	get children__order_of_id(){
		return this.children && this.children.map((e)=>{return e.id}).join();
	}


}

export const registered_models = {
	"User":User, "Show": Show, "Poster": Poster, "Episode": Episode
}










































//
class CoolerModel {
	constructor( kwargs, model = "Base" ){
		for( let k of Object.keys( kwargs ) )
			this[k] = kwargs[k];
	}
}



export class Batches extends BaseModel {
	id: number;
	season_id: number;
	owner_id: number;
	description: string;
	release_date: string;

	signatures: any;
}

export class Season extends BaseModel {
	constructor( kwargs, track_changes = true ){
		//super( kwargs, track_changes );

		if( this.episodes !== null )
			for( let i=0; i < this.episodes.length; i++ )
				this.episodes[i] = new Episode(this.episodes[i], track_changes );

		if( this.batches !== null )
			for( let i=0; i < this.batches.length; i++ )
				this.batches[i] = new Batches(this.batches[i], track_changes );
	}

	id: number;
	type: string;
	full_name: string;
	context_name: string;
	summary: string;

	episodes: Episode[];
	batches: Batches[]
	series_id: number;

	get typeClasses(){
		return {
			'blue'   : this.type  == 'Extra',
			'green'  : this.type  == 'Singleton',
			'grey'   : this.type  == 'Main',
			'orange' : this.type  == 'Creators',
		}
	}

}


export class Series extends BaseModel  {
	constructor( kwargs, track_changes = true ){
		//super( kwargs, track_changes );
		super()
		if( this.seasons !== null )
			for( let i=0; i < this.seasons.length; i++ )
				this.seasons[i] = new Season(this.seasons[i], track_changes );


	}

	id: number;
	name: string;
	type: string;
	status: string;
	poster: string;
	alias_name: string[];
	first_aired: string;
	description: string;
	tvdb_id: number;

	seasons: Season[];

	get statusClasses(){
		return {
			'blue' : this.status == 'Airing', 'green' : this.status == 'Planned', 'grey' : this.status == 'Ended',
			'orange' : this.status == 'Suspended', 'red' : this.status == 'Canceled',
			'brown' : this.status == 'Unknown'
		}
	}

	get typeClasses(){
		return {
			'blue' : this.type  == 'Anime', 'green' : this.type   == 'Cartoon',
			'grey' : this.type  == 'Live Action', 'orange' : this.type   == 'Movie',
			'brown' : this.type  == 'Other'
		}
	}

	get seasons__order_of_id(){
		return this.seasons && this.seasons.map((e)=>{return e.id}).join();
	}


}
*/
