import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { ProfileService } from '../profile/profile.service';
import { CoreService } from '../core.service';

import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { NgClass, NgIf, NgFor, UpperCasePipe, DecimalPipe, TitleCasePipe } from '@angular/common';

@Component({
	selector: 'app-auth',
	imports: [
		FormsModule, ReactiveFormsModule,
		NgClass, NgIf, NgFor,
	],
	templateUrl: './auth.component.html',
	styleUrl: './auth.component.scss'
})
export class AuthComponent {

	is_shown: boolean = false;
	return_path: string = '/';
	invite_code: string = '';


	constructor(
		private core: CoreService,
		private profile: ProfileService,
		private formBuilder: FormBuilder,
		private route: ActivatedRoute,
	){ window['auth'] = this; };


	subscriptions = [];
	ngOnInit() {

		//Get return url from route parameters or default to '/'
		this.return_path = this.route.snapshot.paramMap.get('returnUrl') || '/';
		this.invite_code = this.route.snapshot.paramMap.get('invite_code');

		//Already logged in? then go home!
		if ( this.profile.user ){
			this.core.router.navigate([this.return_path]);
			return;
		}

		//In case logged in from another open tab, continue to the return url.
		this.subscriptions.push(
			this.profile.onUserAuthChange.subscribe(
				(auth) => {
					if( !auth ) return
					this.is_shown = false;
					this.core.router.navigate([this.return_path]);
				}
			)
		)

		this.login_form = this.formBuilder.group({
			username: ['', Validators.required],
			password: ['', Validators.required]
		});

		this.registerForm = this.formBuilder.group({
			new_username: ['', Validators.required],
			new_password: ['', Validators.required]
		});



		if( this.invite_code ) this.onInviteChange();

		setTimeout( () => this.is_shown = true, 0 );
	}



	//Showing and animating Error Message
	error_message_cleared: boolean = false;
	_error_message: string  = '';
	get error_message( ){ return this._error_message; }
	set error_message( msg: string ){
		if( !msg ){ this.error_message_cleared = true; return; }
		this._error_message = msg;
		this.error_message_cleared = false;
	}

	login_form: FormGroup;
	login_form_submitted = false; //The instant the Submit button is pressed
	login_form_submitting = false;
	login_form_submitting_animate = false;
	login_form_onEndAnimation(): void {
		if( this.login_form_submitting ) return;
		this.login_form_submitting_animate  = false;
	}
	login_form_onInputChange(): void {
		this.login_form_submitted = false;
		this.error_message = '';
	}
	async login_form_onSubmit() {
		this.login_form_submitted = true;

		// stop here if form is invalid
		if( this.login_form.invalid ) return;
		this.login_form_submitting_animate = true;

		this.loading = true;

		this.error_message = '';
		let success = await this.profile.login( this.login_form.value )
		if( success ) return;
		this.loading = false;
		let resp = this.profile.last_auth_response;

		if( resp.error_type ) //Fallback Error Message
			this.error_message = `Unexpected Server Issue! (${this.profile.last_auth_response.status_code})`;

		if( resp.json && resp.json['error'] )
			this.error_message = resp.json['error'];


		//if( res.response.inactive )
		//	this.oauthRegState = 3;
	}















	onAcceptActivate(){}
	inviteInvalid: boolean = false;
	inviteLoading: boolean = false;
	inviteCheckLoading: boolean = false;
	inviteCode: string = '';
	invite: any = null;
	onInviteChange(){}


	oauthRegState: number = 0;
	oauthType: string = null;
	oauthAuthError: string = '';

	googleLoading: boolean = false;
	googleUserData: any    = null;
	googleAuth(): void {}

	registerFormChanged(e){}
	onRegSubmit(){}

	loading  : boolean = false;
	submitted: boolean = false;

	registerForm: FormGroup;
	get FR() { return this.registerForm.controls; }

	telegramLoading: boolean = false;
	telegramUserData: any    = null;
	telegramAuth(event): void {}

	oauthBack(){}
	errorMsg: string = '';
	errorMsgClear = false;
	errorShown( e, node ) : void {}

	redirectBack(a,b){}

	/*
	inviteInvalid: boolean = false;
	inviteLoading: boolean = false;
	inviteCheckLoading: boolean = false;
	invite_code: string = '';
	invite: any = null;
	onInviteChange(){
		this.inviteInvalid = false;
		this.inviteCheckLoading = false;
		let code = this.invite_code;
		if( code.length < 12 ) return;

		this.inviteCheckLoading = true;
		this.profile.checkInvite( code ).subscribe((R)=>{
			this.invite = R.json.response;
			this.inviteCheckLoading = false;


			this.inviteInvalid = false;
			if( !this.invite || this.invite.is_expired )
				this.inviteInvalid = true;
		});
	}
	onAcceptActivate(){
		let last = this.profile.last_auth_data;
		last['invite_code'] = this.invite.code;
		this.inviteLoading = true;

		this.profile.login(last).then((success)=>{
			if( !success ){
				this.inviteLoading = false;
				this.setErrorMsg( "IDK! Went wrong" );
			}
		});
	}





	//runs on transitionend
	redirectBack( e, node ): void  {
		if( !this.is_shown && e.target == node ){
			this.router.navigate([this.return_path]);
		}
	}







	//Purely for Interface Logic
	animate  : boolean = false;
	loading  : boolean = false;
	submitted: boolean = false;




	//Google/Telegram Auth
	oauthRegState: number = 0;
	oauthType: string = null;
	oauthAuthError: string = '';

	googleLoading: boolean = false;
	googleUserData: any    = null;
	googleAuth(): void {

		this.googleLoading = true;

		this.socialAuthService.signIn( GoogleLoginProvider.PROVIDER_ID ).then( (userData) => {
			this.googleUserData = userData;
			this.oauthType = 'google';

			this.profile.login({ 'google_id_token' : userData.idToken }).then( (res) => {
				this.googleLoading = false;

				if( res.is_ok )
					{}
				else if ( res.response.inactive )
					this.oauthRegState = 3;
				else
					this.oauthRegState = 1;

				this.socialAuthService.signOut(true).then((r)=>{ console.log(r) });
			});

		}, ()=>{this.googleLoading = false;} );


	}

	telegramLoading: boolean = false;
	telegramUserData: any    = null;
	telegramAuth(event): void {
		if( event.origin !== "https://oauth.telegram.org" ) return;
		let data = JSON.parse( event.data )
		if( data.event !== 'auth_user') return;

		this.oauthType = 'telegram';
		this.telegramLoading  = true;
		this.telegramUserData = data.auth_data

		this.profile.loginUser( { 'telegram_auth_data' : data.auth_data } ).subscribe( (res) => {
			this.googleLoading = false;

			if( res.is_ok )
				{}
			else if ( res.response.inactive )
				this.oauthRegState = 3;
			else
				this.oauthRegState = 1;

		});

	}

	oauthBack(): void {
		if( this.oauthRegState == 3 )
			this.oauthRegState = 0;
		else
			this.oauthRegState -= 1;

		if( this.oauthRegState == 0 )
			this.oauthType = null;
	}

	onRegSubmit() {

		this.oauthAuthError = '';
		this.error_message = '';
		if (this.registerForm.invalid) {
			return;
		}

		let data = { 'username' : this.FR.new_username.value, 'password' : this.FR.new_password.value }
		if(this.oauthType == 'google')
			data['google_id_token'] = this.googleUserData.idToken;
		if(this.oauthType == 'telegram')
			data['telegram_auth_data'] = this.telegramUserData;

		this.profile.createUser(data, this.oauthType)
			.subscribe( (res) => {

				if( res.is_ok )
					this.oauthRegState = 3;
				else
					this.oauthAuthError = res.response.message;

		});

	}

	registerFormChanged(e) : void {
		this.oauthAuthError = '';
	}*/

}
