import { Injectable, Inject, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { HttpClient, HttpRequest }  from '@angular/common/http';

import { Response } from './app.models'

import { delay, map, tap, last, catchError, retry } from "rxjs/operators";
import { Observable, throwError, of, Subject, ReplaySubject, forkJoin } from 'rxjs';




@Injectable({
	providedIn: 'root'
})
export class CoreService {

	constructor(
		public http: HttpClient,
		public sanitizer: DomSanitizer,
		public router: Router,
	){
		window['core'] = this;
		this.log('LOG INIT');
	}

	public developerLogs = new Array();
	log( str:string ){ this.developerLogs.push( new Array(new Date().toISOString(), str) ); }

	public editMode: boolean = false; public toggleEditMode(){ this.editMode = !this.editMode; }
	public onSearch: EventEmitter<string> = new EventEmitter();

	json_request( ...args: any[] ){
		args.unshift(null);
		let raw_request = new (Function.prototype.bind['apply' + ''](HttpRequest, args));
		return this.http.request( raw_request ).pipe(
			last(),
			catchError( (error: any) => {
				let r = new Response(error);
				return of(r);
			}),
			map( (response: any) => {
				if( response.error_type ) return response;
				let r = new Response(response);
				return r;
			}),
		).toPromise();
	}


}
