import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterLink } from '@angular/router';

import { ProfileService } from '../profile/profile.service';
import { CoreService } from '../core.service';

@Component({
	selector: 'app-toolbar',
	imports: [ CommonModule, RouterLink ],
	templateUrl: './toolbar.component.html',
	styleUrl: './toolbar.component.scss'
})
export class ToolbarComponent {
	constructor( public profile: ProfileService, public core: CoreService ) { }
	get currentUser() { return this.profile.user; }


	ngOnInit(){}
}
