<button routerLink="/login" *ngIf="!profile.user">
	<i class="material-icons">how_to_reg</i>Login / Register
</button>

<button class="edit-mode-toggle" *ngIf="currentUser && currentUser.has_permission('Media.change_series')"
	(click)="core.toggleEditMode()" [class.pushed]="core.editMode">
	<i class="material-icons">edit</i>
</button>

<span class="sig-count" *ngIf="currentUser && core.editMode" [class.hard-worker]="currentUser.active_signatures >= 100">
	<small>{{ currentUser.active_signatures }}</small><i class="material-icons">fingerprint</i>
</span>


<button style="margin: auto; text-shadow: 0 0 black, 0 0 black;">
	<i class="material-icons" style="color:yellow; font-size: 20px;">warning</i>
	Under Construction - It's coming along!<br/>
</button>
