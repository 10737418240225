<div #base_panel class="auth-panel" (transitionend)="redirectBack($event, base_panel)" [class.is_outside]="!is_shown">
	<div class="title">
		<div class="animation-container" [class.animate]="login_form_submitting_animate"
			(animationiteration)="login_form_onEndAnimation()"></div>
		LOGIN
		<div class="error-msg" [class.leave]="error_message_cleared">{{ error_message }}</div>
	</div>
	<form [formGroup]="login_form" (ngSubmit)="login_form_onSubmit()">
		@let form = login_form.controls;
		<div class="form-group">
			<input type="text" placeholder=""
				formControlName="username"
				(ngModelChange)="login_form_onInputChange()"
				[class.is-invalid]="login_form_submitted && form['username'].errors"/>

			<label for="username">Username</label>
			<div class="bottom-border"></div>
		</div>
		<div class="form-group">
			<input type="password" placeholder=""
				formControlName="password"
				(ngModelChange)="login_form_onInputChange()"
				[class.is-invalid]="login_form_submitted && form['password'].errors"/>

			<label for="password">Password</label>
			<div class="bottom-border"></div>
		</div>
		<div class="form-group">
			<button [disabled]="loading">Login</button>
		</div>
	</form>

	<!--div class="oauth-login-wapper" [class.opened]="oauthRegState >= 1" [ngClass]="oauthType">
		<header>
			<button class="google-login" (click)="googleAuth()" [class.loading]="googleLoading" [disabled]="this.oauthRegState >= 1">
				<img src="/assets/google-icon.svg" alt="G"/>
			</button>
			<p>
				You're new here, aren't you?<br/>
				<small>Well, let's make you an account!</small>
			</p>
			<div class="telegram-login" [class.loading]="googleLoading" [class.disabled]="this.oauthRegState >= 1">
				<img src="/assets/telegram-icon.png" alt="T"/>
				<div class="telegram-wrapper" (window:message)="telegramAuth($event)">
					<iframe id="telegram-login-quedia_bot" src="https://oauth.telegram.org/embed/quedia_bot?origin=https%3A%2F%2Fquedia.tv&size=large&request_access=write&radius=0" scrolling="no" style="border: medium none; overflow: hidden; height: 40px; width: 219px;" width="238" height="40" frameborder="0"></iframe>
				</div>
			</div>

			<button class="cancel" (click)="oauthBack()">
				<span class="material-icons" [innerText]="(oauthRegState <= 1 || oauthRegState == 3) ? 'close' : 'arrow_back' "></span>
			</button>

			<div class="register-text">
				<small *ngIf="!invite || inviteInvalid">alternatively</small>
				<small *ngIf="invite && !inviteInvalid" class="is-invited">you have an invitation</small>
				<br/>
				<i class="material-icons">chevron_left</i>
				Register Or Login With
				<i class="material-icons">chevron_right</i>

			</div>
		</header>

		<div class="confirmation-box">
			<div class="part-1">
				<div class="id-card" *ngIf="googleUserData && oauthType=='google'">
					<img [src]="googleUserData.image" alt=""/>
					<div>
						<small>id: {{ googleUserData.id }}</small>
						<span>
							<span>Name</span><span>{{ googleUserData.name }}</span>
						</span>
						<span>
							<span>Email</span><span>{{ googleUserData.email }}</span>
						</span>
					</div>
				</div>
				<div class="id-card" *ngIf="telegramUserData && oauthType=='telegram'">
					<img [src]="telegramUserData.photo_url" alt=""/>
					<div>
						<small>id: {{ telegramUserData.id }}</small>
						<span>
							<span>Name</span>
							<span>{{ telegramUserData.first_name }} {{ telegramUserData.last_name }}</span>
						</span>
					</div>
				</div>
				<p>
					So this is the information {{ oauthType }} provided, you can change them later.<br/>
					All We need now is a <i>Username</i> and a <i>Password</i>. <br/>
					Shall we continue?
				</p>
				<div class="continue-wrapper" (click)="oauthRegState = 2">
					<button class="continue">Continue</button>
				</div>
			</div>
			<div class="part-2" [class.active]="oauthRegState == 2">
				<form [formGroup]="registerForm" (ngSubmit)="onRegSubmit()" autocomplete="off">
					<p>
						We need these so you can also login with your <i>username</i> and <i>password</i>, besides using google or telegram.<br/>
						Also, remember others see you by your username, so keep it simple and cool!<br/>
					</p>
					<div class="form-group">
						<input type="text" formControlName="new_username" (ngModelChange)="registerFormChanged($event)"
							[ngClass]="{ 'is-invalid': submitted && FR['new_username'].errors }" placeholder="" autocomplete="off" />
						<label for="new_username">Username <span class="error_msg">{{ oauthAuthError }}</span></label>
						<div class="bottom-border"></div>
					</div>
					<div class="form-group">
						<input type="password" formControlName="new_password" (ngModelChange)="registerFormChanged($event)"
							[ngClass]="{ 'is-invalid': submitted && FR['new_password'].errors }" placeholder="" autocomplete="off" />
						<label for="new_password">Password</label>
						<div class="bottom-border"></div>
					</div>
					<div class="form-group">
						<button [disabled]="loading">Create My Account</button>
					</div>
				</form>
			</div>
			<div class="part-3" [class.active]="oauthRegState == 3">

				<p>
					Your account has been created, to activate it please provide an invite code:
				</p>

				<div class="form-group">
					<input type="text" placeholder="" autocomplete="off" pattern="[a-zA-Z0-9]{0,12}" maxlength="12"
						[class.is-invalid]="inviteInvalid" [(ngModel)]="inviteCode" (ngModelChange)="onInviteChange()"/>
					<label for="code">Invite Code</label>
					<div class="bottom-border"></div>
				</div>

				<div class="invitation-wrapper" *ngIf="invite">
					<img class="profile-picture" alt="" src="/api/profile/get_picture/{{ invite.user.picture }}"/>
					<span class="username">{{ invite.user.username }}</span>

					<span class="text">
						Has invited you to <span class="quedia">Quedia</span>!
					</span>

				</div>

				<div class="invitation-wrapper placeholder" *ngIf="!invite && inviteInvalid">
					<i class="material-icons">sentiment_dissatisfied</i>
					<span class="text">
						Wrong Invite Code
					</span>
				</div>

				<div class="form-group accept-btn" *ngIf="invite && !invite.is_expired">
					<button [disabled]="inviteLoading" (click)="onAcceptActivate()">Accept and Activate</button>
				</div>

				<div class="form-group accept-btn" *ngIf="invite && invite.is_expired">
					<button disabled>Invite Expired</button>
				</div>

			</div>
		</div>
	</div-->


</div>
