import { Routes } from '@angular/router';


//import { AuthGuard }        from './auth/auth.guard'; //Needs to be checked
import { AuthComponent }    from './auth/auth.component';

//import { ProfileComponent } from './profile/profile.component';
//import { HomeComponent }    from './home/home.component';
//import { AboutComponent }   from './about/about.component';
//import { ShowComponent }    from './show/show.component';
//import { InviteComponent }  from './invite/invite.component';


export const routes: Routes = [

	{ path: 'login',             component: AuthComponent },
//	{ path: 'login/:inviteCode', component: AuthComponent },

	/*{ path: 'about',    component: AboutComponent },
	{ path: 'ideas',    component: AboutComponent },
	{ path: 'faq',      component: AboutComponent },
	{ path: 'versions', component: AboutComponent },
	{ path: 'api_docs', component: AboutComponent },
	{ path: 'donate',   component: AboutComponent },
	{ path: 'profile',  component: ProfileComponent, canActivate: [AuthGuard] },
	{ path: 'invite',   component: InviteComponent,  canActivate: [AuthGuard] },


	{ path: 'show/:id',        redirectTo: 'show/:id/' },
	{ path: 'show/:id/:title', component: ShowComponent, canActivate: [AuthGuard] },

	{ path: '', component: HomeComponent, canActivate: [AuthGuard] },*/

];
