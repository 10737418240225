import { Injectable, EventEmitter } from '@angular/core';
//import { HttpClient } from '@angular/common/http';

//import { delay, map, tap, last, catchError, retry }  from "rxjs/operators";
//import { Observable, Subject } from 'rxjs';
import { Response } from '../app.models'


import { CoreService } from '../core.service';
import { User } from '../app.models';

@Injectable({
	providedIn: 'root'
})
export class ProfileService {

	_user: User = null;
	get user() { return this._user; }
	set user(v) {
		if( !v ){ this._user = null; return; }
		let u = new User(v);
		this._user = u;
	}

	onUserAuthChange: EventEmitter<boolean> = new EventEmitter(); //Send: Current Auth State (logged in or not)
	onUserDetailsChange: EventEmitter<string[]> = new EventEmitter(); //Send: List of user keys that changed.

	constructor( public core: CoreService ){
		window['profile'] = this;
		this.whoami_StartPeriodicChecks( 60 );
		this.whoami()
	}




	async logout() {
		let response = await this.core.json_request('POST', '/api/v1/profile/logout', {});
		if( !response.ok ) return false;
		this.user = null;
		this.onUserAuthChange.emit( false );
		return true;
	}

	last_auth_data: object = null;
	last_auth_response: Response = null;
	async login( auth_data ){
		this.last_auth_data = auth_data;
		let response = await this.core.json_request('POST', '/api/v1/profile/login', auth_data);
		this.last_auth_response = response;
		if( !response.ok ) return false;
		this.user = response.json;
		this.onUserAuthChange.emit( true );
		return true;
	}


	async whoami(){
		let response = await this.core.json_request('GET', '/api/v1/profile/whoami');
		if( !response.ok ) return;
		let was_auth = !!this.user;
		this.user = response.json;
		if( was_auth == !this.user )
			this.onUserAuthChange.emit( !!this.user );
	}
	whoami_next_check_seconds = null;
	whoami_next_check_timeout = null;
	whoami_StartPeriodicChecks( seconds: number | null ): void {

		if( seconds )
			this.whoami_next_check_seconds = seconds;

		if( this.whoami_next_check_timeout )
			clearTimeout( this.whoami_next_check_timeout );

		this.whoami_next_check_timeout = setTimeout(
			() => {
				if( this.user ) //No point checking again if we're not logged in.
					this.whoami()

				this.whoami_StartPeriodicChecks( null );
			}, 1000 * this.whoami_next_check_seconds
		)
	}





	/*
	async google_sign_up( signupData ): Observable<any> {
		return this.http.post<any>('/api/v1/profile/google_sign_up', signupData).pipe(
			map( res => { return res; } )
		);
	}

	private telegram_sign_up( signupData ): Observable<any> {
		return this.http.post<any>('/api/v1/profile/telegram_sign_up', signupData);
	}






	public createUser( signupData: object, type: string ): Observable<ApiResponse> {

		var apiResponse: ApiResponse = new ApiResponse();
		var sub$: Subject<ApiResponse> = new Subject<ApiResponse>()

		if( type == 'google')
			this.google_sign_up( signupData ).subscribe(
				res => {
					apiResponse.response = res;
					apiResponse.is_ok    = ( res.status == 'ok')
				},
				error => apiResponse.is_ok = null,
				() => {
					sub$.next( apiResponse ); sub$.complete();
				}
			);

		if( type == 'telegram')
			this.telegram_sign_up( signupData ).subscribe(
				res => {
					apiResponse.response = res;
					apiResponse.is_ok    = ( res.status == 'ok')
				},
				error => apiResponse.is_ok = null,
				() => {
					sub$.next( apiResponse ); sub$.complete();
				}
			);

		return sub$;
	}



	public updateInfo( userData ): Observable<any> {
		return this.app.json_request('POST', '/api/profile/update_info', userData).pipe( tap( res => {
			if( res.json_ok ){
				Object.assign( this.user, res.json.response );
				this.onUserDataChanged.emit();
			}
		}));
	}

	public setTelegram( tgData ): Observable<any> {
		return this.app.json_request('POST', '/api/profile/set_telegram', tgData).pipe( tap( res => {
			if( res.json_ok ){
				Object.assign( this.user, res.json.response );
				this.onUserDataChanged.emit();
			}
		}));
	}

	public setPicture( formData: any ): Observable<any> {
		return this.app.json_request('POST', `/api/profile/set_picture`, formData ).pipe( tap( res => {
			if( res.json_ok ){
				Object.assign( this.user, res.json.response );
				this.onUserDataChanged.emit();
			}
		}));
	}


	public getInvites(): Observable<any> {
		return this.http.request('GET', '/api/profile/get_invites');
	}
	public createInvite( data ): Observable<any> {
		return this.app.json_request('POST', '/api/profile/create_invite', data);
	}
	public revokeInvite( inviteid ): Observable<any> {
		return this.app.json_request('POST', '/api/profile/revoke_invite', { 'id': inviteid });
	}

	public checkInvite( code ): Observable<any> {
		return this.app.json_request('GET', `/api/profile/check_invite/${code}`);
	}
	*/


}
