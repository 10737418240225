import { Component, HostBinding } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { Router, NavigationEnd } from '@angular/router';

import { CoreService } from './core.service';
import { ProfileService } from './profile/profile.service';

import { SidebarComponent } from './sidebar/sidebar.component';
import { ToolbarComponent } from './toolbar/toolbar.component';


import { NgClass, NgIf, NgFor, UpperCasePipe, DecimalPipe, TitleCasePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'


@Component({
	selector: 'app-root',
	imports: [
		SidebarComponent,
		ToolbarComponent,

		RouterOutlet,

		NgClass, NgIf, NgFor, UpperCasePipe, DecimalPipe, TitleCasePipe,
		FormsModule
	],
	templateUrl: './app.component.html',
	styleUrl: './app.component.scss'
})
export class AppComponent {

	//@HostBinding('class.sidenav-open') get isSidebarOpen() { return this.sidebarService.isOpen }
	@HostBinding('class.auth-user') get auth_user(){ return this.profile.user; }
	constructor( public core: CoreService, public profile: ProfileService ){}
	currentUser = null;
	user = null;


	ngOnInit() {
		this.profile.onUserAuthChange.subscribe( (auth) => {
			if( !auth )
				this.signingOutState = 2;
			else
				this.user = this.profile.user;
			//Will set user to null after transitionEnd for a more consistant look.
		});
	}

	//Menu
	menuOpen: boolean = false;
	toggleMenuOpen(): void { this.menuOpen = !this.menuOpen; }

	signingOutFailed = false;
	signingOutState: number = 0; //0 - 1 SigningOut - 2 transitioning before removing user data from interface
	async signOut() {
		this.signingOutState = 1;
		let ok = await this.profile.logout();
		this.signingOutFailed = !ok;

		if( ok )
			this.menuOpen = false;
	}

	signingOutTransitionEnd( e, node ): void {
		if( this.signingOutState == 2 && e.target == node ){
			this.signingOutState  = 0;
			//this.currentUser = null;
			this.core.router.navigate(['/login'])
		}
	}

	//Search Input
	searchString: string = null;
	konamiCodeArray: number[] = [38,38,40,40,37,39,37,39,66,65,13];
	konamiCodeIndex: number = 0;
	konamiCodeActive: boolean = false;
	searchOnKeyUp( event ) {
		if( !this.konamiCodeActive ){
			if( event.keyCode == this.konamiCodeArray[this.konamiCodeIndex] )
				this.konamiCodeIndex += 1
			else
				this.konamiCodeIndex = 0
			if( this.konamiCodeIndex == this.konamiCodeArray.length || (event.keyCode == 13 && this.searchString.toLowerCase()=='uuddlrlrba') ) {
				this.searchString = null;
				this.konamiCodeActive = true;
				return;
			}
		}


		if( event.keyCode == 13 ){
			if( this.core.router.url != '/' ){
				let temp_subscription = this.core.router.events.subscribe((e) => {
					if( e instanceof NavigationEnd && e['url'] == '/' ){
						this.core.onSearch.emit( this.searchString );
						temp_subscription.unsubscribe();
					}
				});
				this.core.router.navigate(['/']);
			} else this.core.onSearch.emit( this.searchString );
		}
	}


}
